html{
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style:none;
  /*火狐下隐藏滚动条*/
  overflow:-moz-scrollbars-none;
}

::-webkit-scrollbar{
  display:none;
}
body{
  min-width: 1024px;
}
.APP{
  /* width: 1920px; */
  width: 100%;
  /* max-width: 1200px; */
  /* height: 1000px; */
  min-width: 1024px;
  background: linear-gradient(180deg, rgba(133, 152, 214, 0.2) 0%, rgba(216, 224, 245, 0) 100%);
  opacity: 1;
}
.header{
  width: 100%;
  /* height: 110px; */
  height: 78px;
  background: #FFFFFF;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(21, 29, 75, 0.1);
  box-sizing: border-box;
}
.header_button {
  margin-right: calc(19% + 120px);
  height: 78px;
}

.header_but_right {
  width: 120px;
  height: 40px;
  background-color: #D8E0F5;
  color: #24308F;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  font-family: PingFang SC;
  border: 0px;
  border-radius: 2px;
  display: inline-block;
  margin-left: 10px;

}

.header_but_right:hover {
  opacity: 0.8;
  cursor: pointer;
}

.logo{
  /* width: 324.1px; */
  height: 53.2px;
  margin-left: 18.9%;
}


.footer{
  width: 100%;
  height: 140px;
  /* background: rgba(62, 47, 122, 0.05); */
  /* background: rgba(62, 47, 122, 0.05); */
  background-color: #eaebf4;
  opacity: 1;
  position: relative;
  padding-top: 33px;
  box-sizing: border-box;
}
.erweimaleft{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  margin-left: 399px;
  top: 22px;
}
.ewmimg{
  width: 77px;
  height: 77px;
  background: #FFFFFF;
  border: 0px solid #D9D9D9;
}
.sys{
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  margin-top: 5px;
}
.care{
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}
.tecnolige{
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 29px; */
}
.nanjing{
  
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  display: flex;
  align-items: center;
}
.nanjinglogo{
  width: 22px;
  height: 22px;
  margin: 0 5px 0 15px;
}
.dashilogo{
  width: 22px;
  height: 22px;
  margin-right: 2px;
}
.yunying{
  margin-top: 14px;
  margin-bottom: 15px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  display: flex;
  justify-content:center;
}

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  /* margin: 0 auto; */
}

.Copyright{
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: #545454;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Copyright a{
  color: #545454;
}
.Copyrightbeian a{
  color: #545454;
}
.Copyrightbeian{
  /* margin-top: 5px; */
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: #545454;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 37px;
}
.targetlink{
  color: #000;
  text-decoration: none;
}



.content{
  width: 100%;
  height: calc(100vh - 218px);
  padding: 44px 0;
  box-sizing: border-box;
  min-height: 490px;
  /* background: linear-gradient(180deg, rgba(133, 152, 214, 0.3) 0%, rgba(216, 224, 245, 0) 100%); */
  background: linear-gradient(180deg, rgba(133, 152, 214, 0.3) 0%, rgba(216, 224, 245, 0.3) 100%);
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentTainer{
  min-width: 1194px;
  /* width: 100%; */
  height: 100%;
  background: rgba(205,215,249, 0.5);
  display:flex;
  align-items: center;
  justify-content: center;
}
.listimg{
  height: 61.42%;
}
.contentright{
  width: 20%;
  /* width: 270px; */
  height: 86.56%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  margin-left: 96px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.wangye{
  /* height: 120px; */
  margin-top: 10px;
  height: 55%;
  display: block;
}

.btnlink{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 50%;
  margin-top: -1%;
}
.download{
  width: 34%;
  padding: 5px 7px;
  opacity: 1;
  border-radius: 23px;
  font-size: 16px;
  font-family: PingFang SC!important;
  font-weight: 500;
  line-height: 30px;
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  margin: 10px 10px;
}
.download_web{
  background: #3F59AE;
}
.download_app{
  background: #105EBF;

}
.downbutton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 50%;
}
/* .myline{
  width: 82.7%;
  height: 0px;
  border: 1px solid #CDD2F1;
  opacity: 1;
} */

.myline {
  position: relative;
  width: 80%;
}

.myline:before {
    content: " ";
    position: absolute;
    z-index: 3;
    /* left: 0; */
    left: -0%;
    bottom: -1px;
    /* width: 220px; */
    width: 100%;
    height: 0px;
    border-bottom: 2px solid #CDD2F1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5)
}


.ant-modal-title {
  color: #fff;
}

.ant-modal-header {
  background-color: #3F59AE;
}

.ant-table-cell {
  font-family: Regular;

}

.ant-modal-close {
  color: #fff;
}

.ant-modal-body {
  padding: 32px;
}

.ant-modal-footer {
  border-top: 0;
}

header .ant-modal .ant-modal-conten {
  padding: 0 0;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-content {
  padding: 0;
}

.ant-modal-title {
  height: 56px;
  color: #fff;
  font-family: Regular;

}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-header {
  color: #fff;

  background-color: #3F59AE;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-title {
  color: #fff;
  font-family: Regular;
  line-height: 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 56px;
  word-wrap: break-word;
  margin: 0;
  margin-left: 30px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-close {
  width: 56px;
  height: 56px;
  top: 0;
  right: 0;
  transition: color 0.3s;
  color: #fff;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
  background: transparent;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 2px 2px;

}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  font-family: Regular;
}
.ant-btn-primary{
  background: #3F59AE;
  border-color: #3F59AE;
}
.ant-btn-primary>span{
  color: #FFFFFF;
}
.ant-btn-primary:hover{
  background: #3F59AE !important;
  border-color: #3F59AE !important;
  opacity: 0.8;
}


:where(.css-dev-only-do-not-override-1xg9z9n).ant-btn.ant-btn-primary>span {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #4967C6;
  border-color: #4967C6;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-body {
  padding: 32px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-table-wrapper .ant-table-thead>tr>th {
  font-family: bold;

}
.ant-table-cell span a{
  color: #3F59AE;

}
.ant-table-cell span a:hover {
  color: #3F59AE;
  opacity: 0.8;
}

.ant-dropdown-trigger{
  width: 120px;
  height: 78px;
  
}
.headbettwen ,
.headbettwen .anticon-down{
  transition: all 0.5s;
  font-family: Regular;

}
.headbettwen:hover .anticon-down{

  transform: rotate(180deg);
}
.headerColor {
  width: 80px;
  height: 78px;
  display: inline-block;
  margin: auto;
  text-align: center;
  line-height: 78px;
  font-family: Regular;
  color: #000;

}

.headbettwen img{
  width: 14px;
  height: 14px;
 display: inline-block;
 
 
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
  font-family: Regular;
  font-family: 16px;
  font-weight: 400; 
  color: rgba(0,0,0,0.85);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover{
  background: #F3F6FF;
  color: #3F59AE;
}
.Link_p{
  font-family: bold;
  color: rgba(0,0,0,0.85);
}